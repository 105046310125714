import { PageHeader } from '@organisms';
import { Col, Form, Space } from 'antd';

import { Button } from 'src/app/framework/organisms/ForgotPasswordButton/ForgotPasswordButton.style';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { FormBasicData } from '../../components/formOffer/FormBasicaData';
import { FormMarketingOffer } from '../../components/formOffer/FormMarketingOffer';
import { FormPaymentOptions } from '../../components/formOffer/FormPaymentOptions';
import { FormProduct } from '../../components/formOffer/FormProduct';
import { FormSensitiveData } from '../../components/formOffer/FormSensitiveData';
import useFormOffer from '../../hooks/useFormOffer';

export const FormOffer: React.FC = () => {
  useBreadcrumbs([
    {
      label: 'Formulário de ofertas',
    },
  ]);

  const [
    onSubmit,
    control,
    handleSubmit,
    defineTitle,
    type,
    isEdit,
    productId,
    isLoading,
    isOfferStandard,
    hasCounter,
    isDisplayCoupon,
    bgCounter,
    subscriptionPeriodOptions,
    hasPromotionalOffer,
    isPhysicProduct,
    physicProductPrimaryColor,
  ] = useFormOffer();

  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={defineTitle}
        subTitle={''}
      />
      <Form
        disabled={type === 'view' || isLoading ? true : false}
        layout='vertical'
        onFinish={handleSubmit(onSubmit, errors => console.log(errors))}
        style={{ marginBottom: '-10%' }}
      >
        <FormBasicData control={control} />
        <FormSensitiveData
          control={control}
          isEdit={isEdit}
          isOfferStandard={isOfferStandard}
          subscriptionPeriodOptions={subscriptionPeriodOptions}
        />
        <FormMarketingOffer
          productId={Number(productId)}
          control={control}
          hasCounter={hasCounter}
          isDisplayCoupon={isDisplayCoupon}
          hasPromotionalOffer={hasPromotionalOffer}
          bgCounter={bgCounter}
        />

        <FormPaymentOptions control={control} />

        <FormProduct
          control={control}
          isPhysicProduct={isPhysicProduct}
          physicProductPrimaryColor={physicProductPrimaryColor}
        />
        <Space
          style={{
            justifyContent: 'space-between',
            position: 'absolute',
            left: '0',
            width: '100%',
            padding: '16px 120px',
            backgroundColor: '#1F1F1F',
          }}
        >
          <Col>{/* <Text>Preview</Text> */}</Col>
          <Col>
            <Button
              htmlType='button'
              style={{ paddingInline: '16px', marginInlineEnd: '12px' }}
              onClick={() => {
                window.location.href = `products/${productId}`;
              }}
            >
              Cancelar
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              style={{ paddingInline: '16px' }}
            >
              Salvar
            </Button>
          </Col>
        </Space>
      </Form>
    </>
  );
};
