import { OptionList } from '@atoms';
import { yupResolver } from '@hookform/resolvers/yup';
import { getRemoteCheckoutConfig } from '@service/api/remoteCheckoutConfig';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { Control, useForm, UseFormHandleSubmit } from 'react-hook-form';
import * as yup from 'yup';

import { offerApi } from '../apis/offerApis';
import { createFormDataFromFormResponse } from '../utils/createFormDataFromFormResponse';
import { formOfferFactory } from '../utils/formOfferFactory';

export default function useFormOffer() {
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');
  const isEdit = type === 'edit';
  const idOffer = urlParams.get('id');
  const idProduct = urlParams.get('productId');
  const codePlanDuo = '43';
  const codePlanFamily = '44';
  const [isLoading, setIsLoading] = useState(false);
  const [isOfferStandard, setIsOfferStandard] = useState(false);
  const [hasCounter, sethasCounter] = useState(false);
  const [subscriptionPeriodOptions, setSubscriptionPeriodPeriodOptions] =
    useState<OptionList>([]);

  useEffect(() => {
    idOffer && getOffers(idOffer);
    if (subscriptionPeriodOptions.length === 0) getSubscriptionPeriods();
  }, [idOffer]);

  function defineTitlePage() {
    switch (type) {
      case 'view':
        return 'Visualizar Oferta';
      case 'edit':
        return 'Editar Oferta';
      default:
        return 'Criar Oferta';
    }
  }

  function definePlanType(accessProfileId?: string) {
    switch (String(accessProfileId)) {
      case codePlanDuo:
        return 'duo';
      case codePlanFamily:
        return 'family';
      default:
        return 'individual';
    }
  }

  async function getOffers(id: string) {
    setIsLoading(true);
    id &&
      (await offerApi.getOfferById(id).then(data => {
        const newOffer = formOfferFactory(data, definePlanType);
        reset(newOffer);
        !newOffer?.endDate && setIsOfferStandard(true);
        setIsLoading(false);
      }));
  }

  async function getSubscriptionPeriods() {
    const configs = await getRemoteCheckoutConfig();
    const options = configs.subscriptionPeriods.map(period => {
      return {
        value: String(period.days),
        label: period.recurrenceDescription,
      };
    });
    setSubscriptionPeriodPeriodOptions(options);
  }

  const validationSchema = yup.object().shape({
    offerName: yup.string().required('O Nome da oferta é obrigatório'),
    offerDescription: yup
      .string()
      .required('A descrição da oferta é obrigatória'),
    bannerWeb: yup.mixed(),
    bannerMobile: yup.mixed(),
    bannerSideWeb: yup.mixed(),
    logo: yup.mixed(),
    fullPrice: yup.string().required('O valor da oferta é obrigatório'),
    installments: yup.string().required('O parcelamento é obrigatório'),
    startDate: yup.date().typeError('A data de início é obrigatória'),
    endDate: yup
      .date()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .test('is-valid-or-null', 'A data de término é obrigatória', value => {
        if (!isOfferStandard && !value) return false;
        return value === null || !isNaN(new Date(value as any).getTime());
      })
      .test(
        'min',
        'A data de término deve ser maior ou igual à data de início',
        (value, context) =>
          isOfferStandard || (!!value && value >= context.parent.startDate)
      ),
    subscriptionPeriod: yup.string(),
    purchaseBtnLabel: yup.string(),
    googleAnalyticsIds: yup.string(),
    facebookPixels: yup.string(),
    privacyPolicyRef: yup
      .string()
      .required('O link para a política de privacidade é obrigatório'),
    termsRef: yup
      .string()
      .required('O link para os termos de responsabilidade é obrigatório'),
    plan: yup.string(),
    hasCounter: yup.boolean(),
    isDisplayCoupon: yup.boolean(),
    isAvailableNuPay: yup.boolean(),
    bgCounter: yup
      .mixed()
      .test(
        'isValidHex',
        'O campo bgCounter deve ser um código HEX válido iniciado com # ou vazio',
        (value, context) => {
          if (!!value && context.parent.hasCounter) {
            // Verifica se o valor é um código HEX válido iniciado com #
            return /^#[0-9A-Fa-f]{6}$/.test(value);
          }
          return true; // Valor vazio é permitido
        }
      ),
    rightImg: yup.mixed().test('img', 'teste', value => {
      console.log(value);
      return true;
    }),
    rightImgMobile: yup.mixed(),
    leftImg: yup.mixed(),
    leftImgMobile: yup.mixed(),
  });

  const onSubmit = () => {
    setIsLoading(true);
    showNotification('submitOffer');
  };

  const { control, handleSubmit, reset, getValues, watch, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      offerName: '',
      accessProfileId: '',
      offerDescription: '',
      bannerWeb: '',
      bannerMobile: '',
      bannerSideWeb: '',
      logo: '',
      fullPrice: '',
      displayPrice: '',
      installments: '',
      startDate: '',
      endDate: '',
      subscriptionPeriod: '365',
      purchaseBtnLabel: '',
      googleAnalyticsIds: '',
      facebookPixels: '',
      isMailPromotional: false,
      renewalDisabled: false,
      renewalOfferId: '',
      disclaimer: '',
      privacyPolicyRef: 'https://www.queimadiaria.com/politica-de-privacidade/',
      termsRef: 'https://www.queimadiaria.com/termos-de-uso/',
      plan: 'individual',
      offerStandart: false,
      hasCounter: false,
      isDisplayCoupon: false,
      isAvailableNuPay: false,
      isAvailablePagaleve: false,
      bgCounter: '',
      rightImg: '',
      rightImgMobile: '',
      leftImg: '',
      leftImgMobile: '',
      hasPromotionalOffer: false,
      promotionalAmount: '',
      qtdPromotionalDays: '',
      qtdDelayedCaptureDays: '',
      profileId: '',
      warrantyDays: '',
      isPhysicProduct: false,
      physicProduct: {
        isFreeShipping: false,
        primaryColor: '#FF4F00',
        footerText: '',
        seo: {
          title: '',
          smallDescription: '',
          largeDescription: '',
          image: '',
          favicon: '',
          siteName: '',
          keywords: [] as string[],
        },
      },
    },
  });

  const [
    isDisplayCoupon,
    bgCounter,
    hasPromotionalOffer,
    isPhysicProduct,
    physicProductPrimaryColor,
  ] = watch([
    'isDisplayCoupon',
    'bgCounter',
    'hasPromotionalOffer',
    'isPhysicProduct',
    'physicProduct.primaryColor',
  ]);

  useEffect(() => {
    if (!hasPromotionalOffer) {
      setValue('promotionalAmount', '');
      setValue('qtdPromotionalDays', '');
      setValue('qtdDelayedCaptureDays', '');
    }
  }, [hasPromotionalOffer]);

  useEffect(() => {
    const hasCounterValue = getValues().hasCounter;
    sethasCounter(hasCounterValue);
    if (hasCounterValue === false) setValue('bgCounter', '');
  }, [watch('hasCounter')]);

  const submitEdit = async () => {
    idProduct &&
      idOffer &&
      (await offerApi
        .putOfferById(
          idOffer,
          await createFormDataFromFormResponse(getValues(), idProduct)
        )
        .then(data => {
          data.data ? showNotification('success') : showNotification('error');
        }));
    setIsLoading(false);
  };

  const submitCreate = async () => {
    idProduct &&
      (await offerApi
        .postOfferById(
          await createFormDataFromFormResponse(getValues(), idProduct)
        )
        .then(data => {
          data.data ? showNotification('success') : showNotification('error');
        }));
    setIsLoading(false);
  };

  const showNotification = (type: string) => {
    switch (type) {
      case 'submitOffer':
        Modal.warning({
          title: 'Atenção!',
          content:
            'Você está prestes a criar uma nova oferta. Por favor, verifique se os dados estão corretos antes de prosseguir, pois essa ação não poderá ser desfeita ou excluída posteriormente.',
          cancelText: 'Cancelar',
          onOk: () => {
            isEdit ? submitEdit() : submitCreate();
          },
          okCancel: true,
        });
        break;
      case 'success':
        Modal.success({
          title: 'Atenção!',
          content: 'Solicitação realizada com sucesso!',
          onOk: () => {
            window.location.href = `/offers?id=${idProduct}`;
          },
        });
        break;
      case 'error':
        Modal.error({
          title: 'Atenção!',
          content:
            'Não foi possivel realizar a sua solicitação, tente editar a oferta para ver os campos que foram criados corretamente!',
          onOk: () => {
            Modal.destroyAll();
          },
          okCancel: true,
        });
        break;
    }
  };

  return [
    onSubmit,
    control,
    handleSubmit,
    defineTitlePage(),
    type,
    isEdit,
    idProduct,
    isLoading,
    isOfferStandard,
    hasCounter,
    isDisplayCoupon,
    bgCounter,
    subscriptionPeriodOptions,
    hasPromotionalOffer,
    isPhysicProduct,
    physicProductPrimaryColor,
  ] as unknown as [
    (data: any) => void,
    Control<any, any>,
    UseFormHandleSubmit<any, undefined>,
    string,
    string,
    boolean,
    string,
    boolean,
    boolean,
    boolean,
    boolean,
    string,
    OptionList,
    boolean,
    boolean,
    string
  ];
}
