import { SwitchController } from '@organisms';
import { Form, Row, Typography } from 'antd';
import { FC } from 'react';
import { Control } from 'react-hook-form';

interface FormPaymentOptionsProps {
  control: Control<any>;
}

export const FormPaymentOptions: FC<FormPaymentOptionsProps> = ({
  control,
}: FormPaymentOptionsProps) => {
  const { Text } = Typography;

  return (
    <Form layout='vertical' requiredMark='optional' style={{ width: '100%' }}>
      <Row style={{ paddingBlock: '40px 24px' }}>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
          }}
        >
          Opções adicionais de pagamento
        </Text>
      </Row>
      <Row style={{ alignItems: 'center', gap: '24px' }}>
        <SwitchController
          control={control}
          label='NuPay'
          defaultValue={false}
          name='isAvailableNuPay'
        />
        <SwitchController
          control={control}
          label='Pagaleve'
          defaultValue={false}
          name='isAvailablePagaleve'
        />
      </Row>
    </Form>
  );
};
